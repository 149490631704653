import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import CaseStudiesPage from '../ui/pages/CaseStudiesPage'

const CaseStudies = props => (
  <>
    <HelmetComponent
      title={metaTags.caseStudiesTitle}
      description={metaTags.caseStudies}
      page="case-studies"
    />
    <AppWrapper {...props}>
      <CaseStudiesPage />
    </AppWrapper>
  </>
)

export default CaseStudies
